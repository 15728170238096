@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.d-hotline.h-btn {
  display: none;
}

/* Hide the scrollbar in Webkit browsers */
::-webkit-scrollbar {
  width: 8px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1; /* Scrollbar thumb */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1; /* Scrollbar thumb on hover */
}
