@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.manageplaylist .ant-table-thead > tr > th {
  font-size: 10px !important;
  font-family: "Inter", sans-serif !important;
  color: #526b84;
  font-weight: 700;
}

.manageplaylist .ant-table-tbody > tr > td {
  font-size: 10px !important;
  font-family: "Inter", sans-serif !important;
  background-color: white !important;
  color: #0f1a24 !important;
}

/* screens from i300 and above */

@media screen and (min-width: 1300) {
  .manageplaylist .ant-table-thead > tr > th,
  .manageplaylist .ant-table-tbody > tr > td {
    font-size: 11px !important;
  }
}

.manage-listtabledataselect .ant-select-selection-item,
.ant-select-selection-placeholder {
  font-size: 11px;
   font-family: "Inter", sans-serif !important;
}

.manage-listtabledataselect .ant-select-item-option-content {
  font-size: 11px;
   font-family: "Inter", sans-serif !important;
}
