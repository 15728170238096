.ant-spin-spinning{
    margin-top: 20% !important;
    margin-left: 51% !important;
}
.ant-spin-lg .ant-spin-dot {
    font-size: 69px !important;
}
.ant-spin-lg .ant-spin-dot i {
    width: 25px !important; 
    height: 25px !important;
}
.ant-spin-dot-item{
    background-color: #93281a !important;
}