.image-container {
  background-image: url("../../assets/img/register-image.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  top: 0;
  bottom: 0;
}

/* .image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
} */

.express-payment-method > .ant-switch {
  background-color: #1a91ff !important;
  border-radius: 50px !important;
}

