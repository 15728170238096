.wardbordscreentablediv .ant-table-thead > tr > th {
  font-size: 10px !important;
  font-weight: 800 !important;
}

.wardbordscreentablediv td.ant-table-cell {
  font-size: 10px !important;
}

@media screen and (max-width: 1350px) {
  .wardbordscreentablediv .ant-table-thead > tr > th {
    font-size: 9px !important;
    font-weight: 800 !important;
  }

  .wardbordscreentablediv td.ant-table-cell {
    font-size: 9px !important;
  }
}
@media screen and (min-width: 1440px) {
  .wardbordscreentablediv .ant-table-thead > tr > th {
    font-size: 12px !important;
    font-weight: 800 !important;
  }

  .wardbordscreentablediv td.ant-table-cell {
    font-size: 12px !important;
  }
}

.wardbordscreen-table-action {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.wardbordscreen-table-action .ant-btn {
  border: none !important;
  outline: none !important;
}
.wardbordscreen-table-action button {
  width: 90% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.wardbordscreen-table-action button:hover {
  background-color: #e8f4ff !important;
}
.wardbordscreen-table-action button img {
  width: 55%;
}

.wardbordscreen-table-action button img:hover {
  border-radius: 4px;
}
