.CreateGamesForm {
    width: 100%;
    float: left;
    padding: 30px 0;
}
.CreateGamesForm .PlayerprimaryCard {
    padding: 15px 15px;
    margin: 0;
}
.TableContainer {
    width: 100%;
    float: left;
}
.TableTeamImg {
    width: 25px;
}
.TableFilter {
    width: 100%;
    float: left;
    padding: 15px 0;
}
.TableFilterText {
    font-size: 14px;
    margin-right: 10px;
    font-weight: 700;
}
.ant-btn.TableActionBtn{
        background-color: #fff;
    color: #9c2729;
    border-color: #9c2729;
}
.ant-btn.TableActionBtn:hover, 
.ant-btn.TableActionBtn:active, 
.ant-btn.TableActionBtn:focus {
background-color: #9c2729;
    color: #fff;
    border-color: #9c2729;
}
 
.btn-active{
    background-color: #30d972;
    border-color: #30d972;
    font-size: 13px;
    color: #fff;
    border-radius: 4px;
}
.btn-deactive{
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    font-size: 13px;
    color: #fff;
    border-radius: 4px; 
}
.CategoriesViewList {
    width: 100%;
    float: left;
  }