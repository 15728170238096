.winlosesDiv .ant-progress-bg {
  height: 4px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.winlosesDiv .ant-progress-text {
  color: #2e4d6b !important;
  font-size: 8px !important;
  padding: 0 !important;
  margin: 0 -50px 18px 0 !important;
}

.flagWinBreakdown .ant-progress-bg,
.kflagWinBreakdown .ant-progress-bg {
  height: 18px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.gameanalytictablediv .ant-table-thead>tr>th,
.formation-analytics .ant-table-thead>tr>th,
.formation-analytics .ant-table-thead>tr>th:empty,
.formation-analytics .ant-table-thead th:first-child,
.formation-analytics .ant-table-tbody td:first-child {
  background: #f3f5f7 !important;
  font-family: "Inter" sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 11.5px !important;
  color: #526b84 !important;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper .ant-table-thead>tr>td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #f3f5f7 !important;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  transition: background 0.3s ease;
}

.gameanalytictablediv td.ant-table-cell {
  font-family: "Inter" sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 11.5px !important;
  color: #526b84 !important;
}

.gameanalytics-table-action,
.playeranalytics-table-action {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.gameanalytics-table-action .ant-btn,
.playeranalytics-table-action .ant-btn {
  border: none !important;
  outline: none !important;
}

.gameanalytics-table-action button,
.playeranalytics-table-action button {
  width: 23% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.gameanalytics-table-action button:hover,
.playeranalytics-table-action button:hover {
  background-color: #e8f4ff !important;
}

.gameanalytics-table-action button img {
  width: 60%;
}

.playeranalytics-table-action button img {
  width: 80%;
}

.gameanalytics-table-action button img:hover,
.playeranalytics-table-action button img:hover {
  border-radius: 4px;
}

.flagWinBreakdown .progress-container {
  position: relative;
  display: inline-block;
}

.kflagWinBreakdown .progress-container {
  position: relative;
  display: inline-block;
}

.flagWinBreakdown .progress-text {
  position: absolute;
  top: 58%;
  left: 30%;
  transform: translate(-50%, -50%);
  color: black;
  border-radius: 3px;
  font-size: 12px;
}

.flagWinBreakdown .progress-text1 {
  position: absolute;
  top: 58%;
  left: 10%;
  color: black;
  transform: translate(-50%, -50%);
}

.gameanalyticsweekselector .ant-select,
.gameanalyticspositionselector .ant-select {
  border-radius: 4px !important;
  height: 43px !important;
  background-color: white !important;
  border: 1px solid #eaedf0 !important;
  width: 200px !important;
}

.gameanalyticsweekselector .ant-select-selector,
.gameanalyticspositionselector .ant-select-selector {
  border: none !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.gameanalyticsweekselector .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.gameanalyticspositionselector .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  display: flex !important;
  align-items: center !important;
  box-shadow: none !important;
}

.gameanalyticsweekselector .ant-select-selector {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  color: #0f1a24 !important;
  font-weight: 700 !important;
}

.gameanalyticspositionselector .ant-select-selector {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  color: #0f1a24 !important;
  font-weight: 400 !important;
}

.gameanalyticsweekselector .ant-select-single .ant-select-selector .ant-select-selection-item {
  color: #0f1a24 !important;
  font-weight: 700 !important;
}

.ggameanalyticspositionselector .ant-select-single .ant-select-selector .ant-select-selection-item {
  color: #0f1a24 !important;
  font-weight: 400 !important;
}

.gameanalyticsweekselector .ant-select-item-option-content {
  font-family: "Inter", sans-serif !important;
  color: #0f1a24 !important;
  font-weight: 700 !important;
}

.gameanalyticspositionselector .ant-select-item-option-content {
  font-family: "Inter", sans-serif !important;
  color: #0f1a24 !important;
  font-weight: 400 !important;
}

.gameanalyticstablediv>td.ant-table-cell {
  font-weight: 700 !important;
}

/* .kflagwinBreakdown > .ant-progress-inner {
  height: 18px !important;
  padding-top: 4px !important;
  background-color: purple !important;
} */

.kflagwinBreakdown .ant-progress-bg,
.kflaglossBreakdown .ant-progress-bg {
  height: 18px !important;
  margin: 0 !important;
  padding: 0 !important;
}

@media screen and (min-width: 768px) {
  .gameanalyticstablediv .ant-table-thead>tr>th {
    font-size: 10px !important;
  }

  .gameanalyticstablediv td.ant-table-cell {
    font-size: 11px !important;
  }
}

@media screen and (min-width: 1024px) {
  .gameanalyticstablediv .ant-table-thead>tr>th {
    font-size: 11px !important;
  }

  .gameanalyticstablediv td.ant-table-cell {
    font-size: 11px !important;
  }
}

@media screen and (min-width: 1440px) {
  .gameanalyticstablediv .ant-table-thead>tr>th {
    font-size: 0.75vw !important;
    font-weight: bolder !important;
  }

  .gameanalyticstablediv td.ant-table-cell>.tabledata {
    font-size: 0.8vw !important;
  }
}

.stats-table>.ant-table-wrapper .ant-table-thead>tr>th {
  background: #eaedf0 !important;
  padding: 0 12px !important;
  height: 50px !important;
  border: 0.5px solid #f3f5f7 !important;
}

.stats-table>.ant-table-wrapper .ant-table-thead>tr>th .ant-table-cell {
  /* color: red !important; */
  font-size: 14px !important;
  padding: 0 !important;
  border-right: 2px solid #f3f5f7;
}

.stats-table>.ant-table-wrapper .ant-table-thead>tr>th {
  /* background: #eaedf0 !important; */
  padding: 0 !important;
  color: #20364b !important;
  height: fit-content !important;
}

@media print {
  .playNum {
    font-size: 14px;
  }

  .stats-table>.ant-table-wrapper .ant-table-tbody>tr>td {
    font-size: 14px !important;
  }

  .stats-table>.ant-table-wrapper .ant-table-thead>tr>th {
    font-size: 14px !important;
    /* border-right: 2px solid #f3f5f7; */
  }

  .stats-table .ant-table-tbody>tr>td {
    height: 80px !important;
    padding: 0px !important;
  }

  .stats-table .ant-table-thead>tr>th {
    height: 40px !important;
    padding: 0 4px !important;
  }
  .scoring-table .ant-table-thead>tr>th {
    height: 80px !important;
    padding: 0 4px !important;
  }

  .stats-table .ant-table-wrapper .ant-table-summary>tr>td {
    height: 40px !important;
  }
}


@media print {
  body{
    background-color: white !important;
  }
  @page {
    size: A4;
    margin: 0.3cm !important; /* Adjust the margin as needed */
  }
  .hidden-print {
    display: none !important;
  }
  .stats-table-con {
    size: landscape;
    width: "100%";
    padding: 0 !important;
    margin: 0 !important;
    /* Ensure no padding */
    /* transform: rotate(90deg); */
    /* transform: rotate(90deg) !important; */
    /* transform-origin: 0 0 !important; */
    /* -webkit-transform: rotate(-90deg); 
     -moz-transform:rotate(-90deg); */
     /* filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3); */
  }

  .hide-in-print {
    display: none !important;
  }

  .stats-table-con-two {
    padding: 1px !important;
  }

  .ant-modal,
  .print-modal {
    display: none !important;
  }
}

.player-stats .ant-table-thead>tr>th,
.player-stats .ant-table-tbody>tr>td {
  padding: 5px !important;
}

.scoring-table>.ant-table-wrapper .ant-table-summary>tr>td:nth-child(12),
.scoring-table>.ant-table-wrapper .ant-table-summary>tr>td:nth-child(15),
.scoring-table>.ant-table-wrapper .ant-table-summary>tr>td:nth-child(17),
.scoring-table>.ant-table-wrapper .ant-table-summary>tr>td:nth-child(21),
.scoring-table>.ant-table-wrapper .ant-table-summary>tr>td:nth-child(23),
.scoring-table>.ant-table-wrapper .ant-table-summary>tr>td:nth-child(24) {
  background-color: #eaedf0 !important;
}