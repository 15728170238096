.CreateCoachForm {
  width: 100%;
  float: left;
  padding: 30px 0;
}
.CreateCoachForm .PlayerprimaryCard {
      padding: 15px 15px;
      margin: 0;
}
.coaches-list-action-btns .TableActionBtn{
  min-width: 75px;
}
.coaches-list-action-btns .TableActionBtn.edit{
  border-color: #1890FF;
  color: #1890FF;
}
.coaches-list-action-btns .TableActionBtn.edit:hover,
.coaches-list-action-btns .TableActionBtn.edit:active,
.coaches-list-action-btns .TableActionBtn.edit:focus{
  border-color: #1890FF;
  color: #fff;
  background-color: #1890FF;
}
.CoachListTitleContainer {
  width: 100%;
  float: left;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CoachListTitle {
  font-size: 18px;
  font-weight: 600;
}
.CoachViewModal .CoachViewInfoGroup{
    margin: 10px 0;
}
.CoachViewModal .CoachViewInfoGroup .infoLabel{
font-size: 14px;
    color: #000;
    font-weight: 400;
}
.CoachViewModal .CoachViewInfoGroup .infoLabelValue{
font-size: 15px;
    color: #000;
    font-weight: 600;
}
.CoachViewModal .ant-modal-header,
.CoachViewModal .ant-modal-footer {
  display: none;
}
.CoachViewModal .ant-modal-body {
  width: 100%;
  float: left;
  position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: auto;
}
.CoachViewModal .ant-modal-close {
  outline: none !important;
  box-shadow: none !important;
}
.CoachViewModal-header {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
    margin-bottom: 10px;
}
.CoachViewModal-header img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 15px;
    background-color: rgb(91 20 27 / 18%);
}
.CoachViewModal-name-bio {
      width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    line-height: 14px;
}
.CoachViewModal-name-bio .coach-name {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.Schoolses-list-action-btns .TableActionBtn{
  min-width: 75px;
}
.coaches-list-action-btns .TableActionBtn.edit{
  border-color: #1890FF;
  color: #1890FF;
  margin-right: 5px;
}
.coaches-list-action-btns .TableActionBtn.edit:hover,
.coaches-list-action-btns .TableActionBtn.edit:active,
.coaches-list-action-btns .TableActionBtn.edit:focus{
  border-color: #1890FF;
  color: #fff;
  background-color: #1890FF;
}
.CoachesListTitleContainer {
  width: 100%;
  float: left;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SchoolsListTitle {
  font-size: 18px;
  font-weight: 600;
}

.react-tel-input .form-control{
  width: 254px !important;
  border-radius: 0px !important;
}