@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.CreateCoachForm {
  width: 100%;
  float: left;
  padding: 30px 0;
}
.CreateCoachForm .PlayerprimaryCard {
  padding: 15px 15px;
  margin: 0;
}
.coaches-list-action-btns .TableActionBtn {
  min-width: 75px;
}
.coaches-list-action-btns .TableActionBtn.edit {
  border-color: #1890ff;
  color: #1890ff;
}
.coaches-list-action-btns .TableActionBtn.edit:hover,
.coaches-list-action-btns .TableActionBtn.edit:active,
.coaches-list-action-btns .TableActionBtn.edit:focus {
  border-color: #1890ff;
  color: #fff;
  background-color: #1890ff;
}
/* 21-02-2020 */

/* table head */
.ant-table-thead > tr > th {
  align-items: center;
  justify-content: space-between;
  background: #f3f5f7 !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  height: 50px !important;
  /* or 19px */

  /* Foundation/Gray/Dark Gray */

  color: #526b84;
}

/* table data */
td.ant-table-cell {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 161.8%;
  color: #0f1a24;
  height: 6.5vh;
}
.seasons-title {
  display: flex;
  margin-bottom: 18px;
}
.seasons-title p,
.seasons-title button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 161.8%;
  /* identical to box height, or 23px */

  text-align: right;

  /* Black */

  color: #0f1a24;
  margin-right: 15px;
  padding: 0;
  margin-bottom: 0px;
}
.seasons-title button .anticon {
  color: #a9b6c2;
  position: relative;
  top: 6px;
  font-size: 12px;
  font-weight: 900;
}
.seasons-title button {
  padding: 8px 16px;
  height: auto;
  width: 200px;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 8px;
}
h3.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */

  /* Black */

  color: #0f1a24;
}
/* th.ant-table-cell {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 161.8%;
    color: #526B84 !important;
  } */

.table-actions, .table-actionsone {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}

.table-actions .ant-btn, .table-actionsone .ant-btn {
  border: none !important;
  outline: none !important;
}
th.ant-table-cell {
  color: #526b84 !important;
}
.table-actions button {
  width: 23% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.table-actionsone button {
  width: 26% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.table-actions button:hover, .table-actionsone button:hover {
  background-color: #1a91ff !important;
}

.table-actions button img {
  width: 80%;
}

.table-actionsone button img  {
  width:85%;
}
.table-actions button img:hover {
  background: rgba(46, 77, 107, 0.1);
  border-radius: 4px;
}
td.ant-table-cell {
  text-transform: capitalize;
}
.was-won,
.was-lost {
  height: 62px;
  display: flex;
  align-items: center;
}
.was-won {
  background: rgba(18, 207, 82, 0.1);
}

.was-lost {
  background: rgba(164, 14, 23, 0.1);
}
.was-won .points {
  color: #00b31e;
  margin-right: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}

.was-lost .points {
  color: #a40e17;
  margin-right: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}

.seasons {
  border: none !important;
}

@media screen and (max-width: 767px) {
  .ant-table-thead > tr > th {
    height: 50px !important;
    font-size: 8px !important;
  }

  td.ant-table-cell {
    height: 50px !important;
    font-size: 8px !important;
  }
}

.admin-evaluationtable .ant-table-thead > tr > th,
.admin-evaluationtable td.ant-table-cell {
  font-size: 11px !important;
}

.coacheval-table .ant-table-thead > tr > th,
.coacheval-table td.ant-table-cell {
  font-size: 11.5px !important;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .coacheval-table .ant-table-thead > tr > th,
  .coacheval-table td.ant-table-cell {
    height: 50px !important;
    font-size: 10px !important;
  }
}

.seasons .ant-select-selection-item,
.ant-select-selection-placeholder,
.eventModal .ant-select-selection-item,
.eventModal.ant-select-selection-placeholder {
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
  color: #0f1a24 !important;
}

.seasons .ant-select-dropdown .ant-select-item-option-content,
.eventModal .ant-select-item-option-content {
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 700 !important;
}

.seasons span.ant-select-selection-item,
.eventModal span.ant-select-selection-item {
  font-weight: 700 !important;
}

.adminTableSelect .ant-select-selection-item,
.adminTableSelect .ant-select-selection-placeholder {
  font-size: 11px !important;
  font-family: "Inter", sans-serif !important;
  color: #0f1a24 !important;
}
