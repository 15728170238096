@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.CreateGamesForm {
    width: 100%;
    float: left;
    padding: 30px 0;
}
.CreateGamesForm .PlayerprimaryCard {
    padding: 15px 15px;
    margin: 0;
}
.TableContainer {
    width: 100%;
    float: left;
}
.TableTeamImg {
    width: 25px;
}
.TableFilter {
    width: 100%;
    float: left;
    padding: 15px 0;
}
.TableFilterText {
    font-size: 14px;
    margin-right: 10px;
    font-weight: 700;
}
.ant-btn.TableActionBtn{
        background-color: #fff;
    color: #9c2729;
    border-color: #9c2729;
}
.ant-btn.TableActionBtn:hover, 
.ant-btn.TableActionBtn:active, 
.ant-btn.TableActionBtn:focus {
background-color: #9c2729;
    color: #fff;
    border-color: #9c2729;
}
.TableContainer .ant-table-thead > tr > th {
    position: relative; 
    text-align: left;
    background: #F3F5F7; 
    transition: background 0.3s ease;
    font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 161.8%;
/* or 19px */


/* Foundation/Gray/Dark Gray */

color: #526B84;


}

.btn-active{
    background-color: #30d972;
    border-color: #30d972;
    font-size: 13px;
    color: #fff;
    border-radius: 4px;
}
.btn-deactive{
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    font-size: 13px;
    color: #fff;
    border-radius: 4px; 
}
.CategoriesViewList {
    width: 100%;
    float: left;
  }

.ant-select-selection-placeholder,
.eventscreen .ant-select-selection-item,
.eventscreen.ant-select-selection-placeholder {
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
  color: #0f1a24 !important;
}

.eventscreen .ant-select-item-option-content {
  font-size: 14px !important;
  font-family: "Inter", sans-serif !important;
  
}