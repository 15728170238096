/* {
    background-image: url('../../assets/img/loginbg.svg');
    background-image: url('../../assets/img/loginbg.svg');
    background-color: #000000;
        background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.rectangle-register1 {
  /* background: #DA3434; */
  width: 100%;
  height: 100vh;
  position: absolute;
  background-image: url("../../assets/img/gradient.svg");
  background-repeat: no-repeat;
  background-position: top left;
}

.register-container {
  background-image: url("../../assets/img/register-image.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  top: 0;
  bottom: 0;
}

.register-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/gradient.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.rectangle-register2 {
  /* background: #DA3434; */
  width: 100%;
  height: 100vh;
  position: absolute;
  background-image: url("../../assets/img/gradient.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  /* transform: rotate(180deg);  */
}
