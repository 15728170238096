@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.CreateCoachForm {
  width: 100%;
  float: left;
  padding: 30px 0;
}
.CreateCoachForm .PlayerprimaryCard {
      padding: 15px 15px;
      margin: 0;
}
.coaches-list-action-btns .TableActionBtn{
  min-width: 75px;
}
.coaches-list-action-btns .TableActionBtn.edit{
  border-color: #1890FF;
  color: #1890FF;
}
.coaches-list-action-btns .TableActionBtn.edit:hover,
.coaches-list-action-btns .TableActionBtn.edit:active,
.coaches-list-action-btns .TableActionBtn.edit:focus{
  border-color: #1890FF;
  color: #fff;
  background-color: #1890FF;
}
.ant-select-selector {
      /* padding: 0.5rem !important; */
}
.CoachListTitleContainer {
  width: 100%;
  float: left;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CoachListTitle {
  font-size: 18px;
  font-weight: 600;
}
.CoachViewModal .CoachViewInfoGroup{
    margin: 10px 0;
}
.CoachViewModal .CoachViewInfoGroup .infoLabel{
font-size: 14px;
    color: #000;
    font-weight: 400;
}
.CoachViewModal .CoachViewInfoGroup .infoLabelValue{
font-size: 15px;
    color: #000;
    font-weight: 600;
}
.CoachViewModal .ant-modal-header,
.CoachViewModal .ant-modal-footer {
  display: none;
}
.CoachViewModal .ant-modal-body {
  width: 100%;
  float: left;
  position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: auto;
}
.CoachViewModal .ant-modal-close {
  outline: none !important;
  box-shadow: none !important;
}
.CoachViewModal-header {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
    margin-bottom: 10px;
}
.CoachViewModal-header img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 15px;
    background-color: rgb(91 20 27 / 18%);
}
.CoachViewModal-name-bio {
      width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    line-height: 14px;
}
.CoachViewModal-name-bio .coach-name {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.Schoolses-list-action-btns .TableActionBtn{
  min-width: 75px;
}
.coaches-list-action-btns .TableActionBtn.edit{
  border-color: #1890FF;
  color: #1890FF;
  margin-right: 5px;
}
.coaches-list-action-btns .TableActionBtn.edit:hover,
.coaches-list-action-btns .TableActionBtn.edit:active,
.coaches-list-action-btns .TableActionBtn.edit:focus{
  border-color: #1890FF;
  color: #fff;
  background-color: #1890FF;
}
.CoachesListTitleContainer {
  width: 100%;
  float: left;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SchoolsListTitle {
  font-size: 18px;
  font-weight: 600;
}




.coacheslisttablediv .ant-table-thead > tr > th,
.coacheslisttablediv td.ant-table-cell {
  font-size: 8px !important;
   font-family: "Inter" !important;
}

@media screen and (min-width:768px) {
.coacheslisttablediv .ant-table-thead > tr > th,
.coacheslisttablediv td.ant-table-cell,.status  {
  font-size: 10px !important;
}

}

@media screen and (min-width:1200px) {
.coacheslisttablediv .ant-table-thead > tr > th,
.coacheslisttablediv td.ant-table-cell, .status {
  font-size: 11px !important;
}

}

@media screen and (min-width:1400px) {
.coacheslisttablediv .ant-table-thead > tr > th,
.coacheslisttablediv td.ant-table-cell, .status {
  font-size: 12px !important;
}

}


.coachlistsearch > :where(.css-dev-only-do-not-override-ixblex).ant-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top;
    background-color: yellow !important;
}

.coachstatusswitchdiv > .ant-switch .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  font-size: 11px;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
  transition: all 0.2s ease-in-out;
  content: url(../../../assets/img/Vectorredcancel.png);
}

.coachstatusswitchdiv > .ant-switch .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: "";
}

.coachstatusswitchdiv > .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: url(../../../assets/img/Vectorgreen.svg);
}

.coachstatusswitchdiv > .ant-switch {
  background-color: #A40E17 !important;
  width:80px;
  height: 28px;
  position: relative;
}

.coachstatusswitchdiv > .ant-switch-checked {
  background-color: #00B21E !important;
}


:where(.css-dev-only-do-not-override-ixblex).ant-switch .ant-switch-inner .ant-switch-inner-checked, :where(.css-dev-only-do-not-override-ixblex).ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    display: block;
    color: #fff;
    font-size: 14px;
    transition: margin-inline-start 0.2s ease-in-out,margin-inline-end 0.2s ease-in-out;
    pointer-events: none;
    position: absolute;
    top:10%;
}