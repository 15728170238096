/* .PageHeaderLogoContainer {
	width: 100%;
	float: left;
	padding: 11px 0;
	background-color: #fff;
}
.PageMainLogo {
	width: 140px;
	margin-right: 15px;
}
.TeamLogoName {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.PageHeaderLogoContainerInner {
	width: 100%;
	float: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}
.LogoSet{
	width: 240px;
	display: flex;
	/* justify-content: center; */
/* }
.containerx{
	padding: 0 30px;
}
.PageHeaderLogoContainerInnerLeft {
	display: flex;
    align-items: center;
	/* width: 240px; */

/*}
.PageTeamLogo {
	width: 40px;
	float: left;
}
.TeamLogoName {
	float: left;
	padding-left: 15px;
	position: relative;
}
.TeamLogoName:before {
	content: "";
	position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0.5px;
    background-color: #D5D5D5;
}
.PageTeamLogoName {
	max-width: 140px;
    font-size: 12px;
    margin-left: 6px;
    font-weight: bold;
}
.PageHeaderLogoContainerInnerRight {
	display: flex;
	align-items: center;
	background-color: #F3F5F7;
	padding: 5px 20px;
}
.HeaderUserImg {
	    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: solid .5px #9c2927;
}
.CoachNamePosition {
	display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #000;
    margin: 0 8px;
    margin-right: 15px;
}
.CoachName {
	font-size: 13px;
	font-weight: bold;
}
.HeaderMenuIcons {
	font-size: 18px;
    color: #A9B6C2;
    cursor: pointer;
        min-width: 18px;
    text-align: right;
}
.PageHeaderMenuContainer {
	width: 100%;
	float: left;
	background: linear-gradient(0deg, #939393 0%, #961A22 0%, rgba(193, 39, 48, 0.75) 100%);
	height: 0;
    overflow: hidden;
    transition: all ease .5s;
}
.PageHeaderMenuContainer.active {
	height: 54px;
	transition: all ease .5s;
}
.PageHeaderMenuContainerInner {
	width: 100%;
    float: left;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}
.PageHeaderMenuContainerInner a {
	font-weight: 400;
font-size: 13px;
line-height: 14px;
color: #FFFFFF;
}
.PageHeaderMenuContainerInner a:hover,
.PageHeaderMenuContainerInner a:active,
.PageHeaderMenuContainerInner a:focus {
text-decoration: underline;
color: #FFFFFF;
}
.PageHeaderMenuContainerInner a.active {
	text-decoration: underline;
	font-weight: 700;
}
.MobileOverLay {
	display: none;
	position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgb(0 0 0 / 70%);
        opacity: 0;
        z-index: -1;
        transition: all ease .3s;
            height: 100vh;
}
.PageHeaderMenuContainer .PageHeaderLogoContainerInnerRight {
	display: none;
}
.PageHeaderMenuContainerInner .logout-btn {
	font-weight: 400;
font-size: 13px;
line-height: 14px;
color: #FFFFFF;
margin-top: -9px
}
.PageHeaderMenuContainerInner .logout-btn:hover,
.PageHeaderMenuContainerInner .logout-btn:active,
.PageHeaderMenuContainerInner .logout-btn:focus {
text-decoration: underline;
color: #FFFFFF;
}
.PageHeaderMenuContainerInner .logout-btn.active {
	text-decoration: underline;
	font-weight: 700;
}

.mobile-logout-btn{
	padding-left: 0;
	text-align: left;
	margin-top: 10px;
	color: #fff;
  } */
  @media print {
	.hide-in-print {
		display: none !important;
	}
  }