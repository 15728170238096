.Schoolses-list-action-btns .TableActionBtn{
  min-width: 75px;
}
.schools-list-action-btns .TableActionBtn.edit{
  border-color: #1890FF;
  color: #1890FF;
  margin-right: 5px;
}
.schools-list-action-btns .TableActionBtn.edit:hover,
.schools-list-action-btns .TableActionBtn.edit:active,
.schools-list-action-btns .TableActionBtn.edit:focus{
  border-color: #1890FF;
  color: #fff;
  background-color: #1890FF;
}
.ScholsListTitleContainer {
  width: 100%;
  float: left;
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.SchoolsListTitle {
  font-size: 18px;
  font-weight: 600;
}
.ant-btn {
  margin-bottom: 6px;
}
.ant-spin-nested-loading > div > .ant-spin{
  position: absolute;
    top: -98px !important;
    left: 0;
    z-index: 4;
    display: block;
    width: 0% !important;
    height: 0% !important;
     max-height: 0px !important;
}

.add_featured_photo .ant-upload-list-picture-card .ant-upload-list-item-actions{
  display: none !important;
}
.SchoolsListTitleContainer {
	margin: 10px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.CreateSchoolAdmin-inner {
width: 100%;
float: left;
background-color: #fff;
    padding: 15px 15px;
    border-radius: 4px;
}
.CreateSchoolAdmin-inner .ant-steps-item-process .ant-steps-item-icon {
    border-color: #bb454c;
}
.CreateSchoolAdmin-inner .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #bb454c;
}
.steps-content {
	width: 100%;
    float: left;
    padding: 20px 0;
}
.CreateSchoolAdmin-inner .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #bb454c;
}
.CreateSchoolAdmin-inner .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #bb454c;
}
.CreateSchoolAdmin-inner .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #bb454c;
}