@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://use.fontawesome.com/releases/v5.15.2/css/all.css");

body {
  background-color: #f3f5f7;
  overflow-x: hidden;
}
.d-hotline.h-btn {
  display: none !important;
}
/*.full-width {
  width: 100%;
  float: left;
}
.PageHeaderMenuContainerInner.MobileMenu {
    display: none;
}
@media (max-width: 1024px) {
    .ant-form-item-control {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 117px;
}
}
@media (max-width: 991px) {
    
}
@media (max-width: 767px) {
  .PageHeaderLogoContainerInner {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.PageHeaderLogoContainerInnerRight {
    margin-top: 5px;
}
.HeaderMenuIcons {
    position: absolute;
    right: 0;
    top: 5px;
}
.PlayerFilterForm {
    width: 100%;
    float: left;
    display: block;
}
.PlayerFilterForm.ant-form-vertical .ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.MobileOverLay {
  display: block;
}
.PageHeaderMenuContainer {
    height: 100vh;
    overflow: hidden;
    transition: all ease .5s;
    position: fixed;
    z-index: 10;
    width: 250px;
    left: -250px;
}
.PageHeaderMenuContainer.active {
  left: 0;
  height: 100vh;
}
.PageHeaderMenuContainerInner {
    width: 100%;
    float: left;
    padding: 20px 0;
    padding-top: 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.PageHeaderMenuContainerInner a {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
    padding: 20px 0;
}
.PageHeaderMenuContainer.active + .MobileOverLay{
  opacity: 1;
    z-index: 9;
    transition: all ease .3s;
}
.PrimaryCardForm.ant-form-horizontal .ant-form-item {
    padding: 0;
}
.PaginationContainer {
    flex-direction: column;
        align-items: flex-start;
}
.PaginationContainerInner {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 10px;
}
.PaginationLabel.ml-3 {
    margin-left: 0 !important;
}
.copyRights {
    margin-top: 0px;
}
.ant-form-item-control {
    width: 100%;
}
.copyRights {
    float: left;
}
.PageHeaderLogoContainer .PageHeaderLogoContainerInnerRight .HeaderUserImg,
.PageHeaderLogoContainer .PageHeaderLogoContainerInnerRight .CoachNamePosition {
    display: none;
}
.PageHeaderMenuContainer .PageHeaderLogoContainerInnerRight {
    display: flex;
        margin-top: 30px;
}
.PageHeaderMenuContainer .PageHeaderLogoContainerInnerRight .CoachName {
    font-size: 17px;
    font-weight: bold;
}
.PageHeaderMenuContainer .PageHeaderLogoContainerInnerRight .CoachNamePosition {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    margin: 0 8px;
    margin-right: 15px;
}
.PageHeaderMenuContainer .PageHeaderLogoContainerInnerRight .HeaderUserImg {
    width: 50px;
    height: 50px;
}
.PageHeaderMenuContainerInner.WebMenu {
    display: none;
}
.PageHeaderMenuContainerInner.MobileMenu {
    display: flex;
}
}
@media (max-width: 575px) {} */

/* .ant-switch-handle > ::before {
    content: "\f00c";
    font-family: "AntDesign";
    font-size: 16px;
    color: #fff;
    background-color: green;
} */
#effort > .ant-switch .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  font-size: 11px;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
  transition: all 0.2s ease-in-out;
  content: url(./assets/img/Vectorredcancel.png);
}

#effort > .ant-switch .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: "";
}

#effort > .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: url(./assets/img/Vectorbluecheck.svg);
}

#execution > .ant-switch .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  font-size: 11px;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
  transition: all 0.2s ease-in-out;
  content: url(./assets/img/Vectorredcancel.png);
}

#execution > .ant-switch .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: "";
}

#execution > .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: url(./assets/img/Vectorbluecheck.svg);
}

.flagged > .ant-switch {
  background-color: #d1d1d6 !important;
}
.flagged > .ant-switch-checked {
  background-color: #e2b517 !important;
}

.flagged > .ant-switch .ant-switch-handle::before {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
  transition: all 0.2s ease-in-out;
}
.fumble > .ant-switch .ant-switch-handle::before,
.fumble > .ant-switch-checked .ant-switch-handle::before {
  /* display: flex !important;
  align-items: center !important;
  justify-content: center !important; */
  outline: none;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 0;
  /* inset-inline-end: 0; */
  bottom: 0;
  /* inset-inline-start: 0; */
  border-radius: 10px !important;
  box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
  transition: all 0.2s ease-in-out;
  /* width: 20px !important;
  height: 20px !important; */
}
.flagged > .ant-switch .ant-switch-handle::before {
  content: url("../src/assets/img/Vector.svg");
}
.fumble > .ant-switch .ant-switch-handle::before {
  content: url("../src/assets/img/mask-circlegrey.svg");
}
.flagged > .ant-switch .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: "";
}
.flagged > .ant-switch-checked .ant-switch-handle::before {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  border-radius: 9px;
}
.flagged > .ant-switch-checked .ant-switch-handle::before {
  content: url("../src/assets/img/black-flag.svg");
}
.fumble > .ant-switch-checked .ant-switch-handle::before {
  content: url("../src/assets/img/mask-circle.svg");
}

.row-disabled .actor button,
.row-disabled .actor .ant-radio-group,
.row-disabled .actor .ant-checkbox-group {
  opacity: 0;
}
.row-disabled .actor .button-group {
  background-color: #d1d1d6 !important;
  border-radius: 10px;
  width: 56px;
  height: 25px;
  margin: auto;
}
.row-disabled .actor {
  background-color: #d1d1d6 !important;
  border-radius: 10px;
  width: 50px;
  margin: auto;
}

.turn-over > .ant-switch {
  background-color: #d1d1d6 !important;
}

.fumble > .ant-switch {
  background-color: #d1d1d6 !important;
}

.fumble > .ant-switch-checked {
  background-color: #a40e17 !important;
}

.turn-over > .ant-switch-checked {
  background-color: #93d789 !important;
}
.turn-over > .ant-switch .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  border-radius: 50px;
  box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
  transition: all 0.2s ease-in-out;
  content: "0";
}

.turn-over > .ant-switch .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: "";
}
.turn-over > .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: "1";
}

/* .fumble > .ant-switch-checked .ant-switch-handle::before {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  content: url("../src/assets/img/mask-circle.svg");
} */
.ant-radio-button-wrapper {
  /* margin: auto; */
  height: 24px;
  width: 28px;
}

.ant-radio-button-wrapper > span {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
/* .ant-radio-button-wrapper>.ant-radio-button>img {
    width: 100px;
} */

.ant-switch:focus {
  box-shadow: none !important;
  outline: none !important;
}

.ant-switch {
  height: 24px;
  width: 48px;
  border-radius: 8px !important;
}
.ant-switch-handle {
  margin-top: 2% !important;
}

.completionsradiodiv > .ant-radio-group > label {
  position: relative;
}

.completionsradiodiv > .ant-radio-group > label > span {
  position: absolute;
  left: 10%;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.completi0nsradiodiv > .ant-radio-group > label > span > img {
  width: 18px;
  filter: "brightness(0) invert(1)";
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.good.css-dev-only-do-not-override-ixblex
  ::before {
  border: none !important;
}
.discipline > .ant-radio-group > label {
  position: relative;
}

.discipline > .ant-radio-group > label > span {
  position: absolute;
  left: 10%;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a9b6c2 !important;
}
.completionsradiodiv > .ant-radio-group > label > span {
  position: absolute;
  left: 10%;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a9b6c2 !important;
}

.footworkdiv > .ant-radio-group > label > span > img {
  position: absolute;
  left: 10%;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discipline > .ant-radio-group > label > span > img {
  position: absolute;
  left: 10%;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completionsradiodiv > .ant-radio-group > label > span > img {
  position: absolute;
  left: 10%;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

:where(
    .css-dev-only-do-not-override-ixblex
  ).ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  display: none !important;
}

.discipline
  > .ant-radio-group
  > .ant-radio-button-wrapper-checked:not(:first-child)
  img {
  filter: grayscale(100%) invert(100%);
}
.discipline
  > .ant-radio-group
  > .ant-radio-button-wrapper-checked:first-child
  img {
  filter: brightness(0) invert(1);
}

.disciplinesradiodiv
  > .ant-radio-group
  > .ant-radio-button-wrapper-checked
  img {
  filter: brightness(0) invert(1);
}
.disciplinesradiodiv .button-group {
  display: flex;
}
.disciplinesradiodiv .button-group .ant-checkbox-group label:first-child {
  border-bottom-left-radius: 9px;
  border-top-left-radius: 9px;
}

.disciplinesradiodiv .button-group .ant-checkbox-group label:last-child {
  border-bottom-right-radius: 9px;
  border-top-right-radius: 9px;
}

.disciplinesradiodiv .button-group img {
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reception .button-group img {
  width: 30px !important;
}

.reception .ant-checkbox-wrapper.ant-checkbox-wrapper-checked img {
  filter: brightness(1) invert(0) !important;
}

.reception .ant-checkbox-wrapper.ant-checkbox-wrapper-checked label span {
  color: #ffffff !important;
}

.reception .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  background-color: #a40e17 !important;
}

.disciplinesradiodiv .ant-checkbox-wrapper.ant-checkbox-wrapper-checked img {
  filter: brightness(0) invert(1) !important;
}

.disciplinesradiodiv .ant-checkbox-wrapper {
  width: 28px;
  height: 28px;
  margin-inline-start: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #78bf6e !important;
}
.disciplinesradiodiv .ant-checkbox-wrapper {
  border-left: 1px solid #a9b6c2;
}
.disciplinesradiodiv.reception .ant-checkbox-wrapper {
  background-color: #f3f5f7 !important;
}
.disciplinesradiodiv .ant-checkbox-wrapper label span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #a9b6c2 !important;
}

.disciplinesradiodiv.other .ant-checkbox-wrapper {
  background: #f2f2f7 !important;
}
/* .disciplinesradiodiv .ant-checkbox-wrapper:hover {
  border-color: #A40E17 !important;
} */

.disciplinesradiodiv .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  background-color: #a40e17 !important;
}

.disciplinesradiodiv .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span {
  color: #ffffff !important;
}

/* .disciplinesradiodiv .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover {
  background-color: #A40E17 !important;
  border-color: #A40E17 !important;
} */

.disciplinesradiodiv.other
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    3
  ).ant-checkbox-wrapper-checked:hover,
.disciplinesradiodiv.other
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    3
  ).ant-checkbox-wrapper-checked {
  background-color: #ff8080 !important;
}

.disciplinesradiodiv.reception
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    3
  ).ant-checkbox-wrapper-checked:hover,
.disciplinesradiodiv.completion
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    3
  ).ant-checkbox-wrapper-checked:hover,
  .disciplinesradiodiv.reception
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    3
  ).ant-checkbox-wrapper-checked,
.disciplinesradiodiv.reception
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    4
  ).ant-checkbox-wrapper-checked,
.disciplinesradiodiv.completion
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    4
  ).ant-checkbox-wrapper-checked{
  background-color: #93d789 !important;
}

.disciplinesradiodiv.reception
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    3
  ).ant-checkbox-wrapper-checked
  span {
  color: #ffffff !important;
}

.disciplinesradiodiv.other
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    3
  ).ant-checkbox-wrapper-checked
  img {
  filter: brightness(0) invert(0) !important;
}

.disciplinesradiodiv.other
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    4
  ).ant-checkbox-wrapper-checked:hover,
.disciplinesradiodiv.other
  .ant-checkbox-wrapper:not(:first-child):nth-child(
    4
  ).ant-checkbox-wrapper-checked
  span {
  color: #ffffff !important;
}

.disciplinesradiodiv.other
  .ant-checkbox-wrapper:nth-child(2).ant-checkbox-wrapper-checked:hover,
.disciplinesradiodiv.other
  .ant-checkbox-wrapper:nth-child(2).ant-checkbox-wrapper-checked {
  background-color: #a40e17 !important;
}

.disciplinesradiodiv.other
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  img {
  /* filter: grayscale(100%) brightness(200%) !important; */
}

.disciplinesradiodiv.other
  .ant-checkbox-wrapper:not(:first-child).ant-checkbox-wrapper-checked
  span {
  color: #0f1a24 !important;
  font-weight: 700 !important;
}

.disciplinesradiodiv.reception
  .ant-checkbox-wrapper:not(:first-child).ant-checkbox-wrapper-checked
  span {
  color: #ffffff !important;
}

.disciplinesradiodiv .ant-checkbox + span {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
}
.disciplinesradiodiv .ant-checkbox + span img {
}
.disciplinesradiodiv .ant-checkbox-wrapper .ant-checkbox-inner {
  display: none;
}
.disciplinesradiodiv input.ant-checkbox-input {
  display: none;
}
.disciplinesradiodiv
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  .ant-checkbox-inner {
  display: none;
  background-color: #fff;
}
/* .discipline > .ant-radio-group >  .ant-radio-button-wrapper-checked > span > img {
    filter: grayscale(100%) !important;
} */

.discipline > .ant-radio-group > .ant-radio-button-checked > label > span {
  font-size: 50px !important;
}
/* .disciplined > .ant-radio-group > .ant-radio-button-wrapper{
  background-color: #fdfdfd !important;
} */

.ant-progress-steps-outer {
  width: 100% !important;
  height: 20px;
}
.ant-progress-steps-item {
  width: 1.6rem !important;
  height: 100% !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 18px 20px;
}
