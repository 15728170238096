.CreateCoachForm {
  width: 100%;
  float: left;
  padding: 30px 0;
}
.CreateCoachForm .PlayerprimaryCard {
  padding: 15px 15px;
  margin: 0;
}
.coaches-list-action-btns .TableActionBtn {
  min-width: 75px;
}
.coaches-list-action-btns .TableActionBtn.edit {
  border-color: #1890ff;
  color: #1890ff;
}
.coaches-list-action-btns .TableActionBtn.edit:hover,
.coaches-list-action-btns .TableActionBtn.edit:active,
.coaches-list-action-btns .TableActionBtn.edit:focus {
  border-color: #1890ff;
  color: #fff;
  background-color: #1890ff;
}
/* 21-02-2020 */
.evaluvationPage .ant-collapse {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
}
.evaluvationPage .ant-collapse > .ant-collapse-item {
  width: 100%;
  float: left;
  border-bottom: 1px solid #f0f0f0;
  background-color: #b23a41;
  margin-bottom: 15px;
  position: relative;
}
.evaluvationPage .ant-collapse > .ant-collapse-item:last-child {
  margin-bottom: 0;
}
.evaluvationPage .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: rgb(0 0 0);
  background-color: #d3d3d3;
}
.evaluvationPage
  .ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header {
  color: rgb(255 255 255);
  background-color: #9c2d36;
}
.evaluvationPage .ant-collapse-content {
  width: 100%;
  float: left;
}
.evaluvationPage .ant-collapse-content > .ant-collapse-content-box {
  padding: 15px;
  width: 100%;
  float: left;
}
.evaluvationPage .pageTitle {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding: 15px 0;
  padding-top: 10px;
}
.evaluvationPage .CreateGameBtn {
  position: absolute;
  top: 7px;
  right: 16px;
}
.evaluvationTable .ant-table-row-expand-icon-cell .ant-table-row-expand-icon,
.evaluvationTable
  .ant-table-row-expand-icon-cell
  .ant-table-row-expand-icon:hover,
.evaluvationTable
  .ant-table-row-expand-icon-cell
  .ant-table-row-expand-icon:active,
.evaluvationTable
  .ant-table-row-expand-icon-cell
  .ant-table-row-expand-icon:focus {
  margin: 0 auto;
  float: none;
  background-color: #000;
  border-color: #000;
  margin-top: 5px;
}
.evaluvationTable .ant-table-row-expand-icon::before,
.evaluvationTable .ant-table-row-expand-icon::after {
  color: #fff;
}
.gameListTagContainer {
  width: 100%;
  float: left;
}
.gameListTagContainer .gameListTag.ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
  border: solid 1px #000;
  padding: 10px 20px;
  height: auto;
}
.gameListTagContainer .gameListTag.ant-btn:hover,
.gameListTagContainer .gameListTag.ant-btn:active,
.gameListTagContainer .gameListTag.ant-btn:focus {
  background-color: #9c2d36;
  border-color: #9c2d36;
  color: #fff;
}
.evaluvationReportTable .PlaysNameTitle {
  font-size: 15px;
  padding: 12px 8px;
  float: left;
  width: 100%;
  background-color: #000;
  color: #fff;
  font-weight: 700;
  text-align: left;
}
.evaluvationReportTable
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td[colSpan="5"] {
  padding: 0;
}
.evaluvationTableTitle {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #d5eed3;
  padding: 8px 10px;
  font-size: 16px;
  border-bottom: solid 1px #dfdfdf;
}
.TableContainer.evaluvationReportTable {
  margin-top: 0px;
}
.TableContainer.evaluvationReportTable
  .ant-table.ant-table-bordered
  > .ant-table-container {
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.TableContainer.evaluvationReportTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 0;
}
.TableContainer.evaluvationReportTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 0;
}
.UpdateGameResultModal .ant-modal-header,
.UpdateGameResultModal .ant-modal-footer,
.UpdateGameResultModal .ant-modal-close {
  display: none;
}
.UpdateGameResultModal .ant-modal-body {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 4px;
}
.UpdateGameResultModal .PlayerFilterForm.ant-form-vertical .ant-form-item {
  margin-bottom: 10px;
}
.UpdateGameResultModal .BoldLabel {
  width: 100%;
  float: left;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.UpdateGameResultModal .ant-checkbox-inner {
  border-color: #ac0000;
}
.UpdateGameResultModal .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.UpdateGameResultModal .ant-checkbox:hover .ant-checkbox-inner,
.UpdateGameResultModal .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ac0000;
}
.UpdateGameResultModal .ant-checkbox-checked::after {
  border: 1px solid #ac0000;
}
.UpdateGameResultModal .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ac0000;
  border-color: #ac0000;
}
.UpdateGameResultModal
  .PlayerFilterForm
  .ant-form-item-control-input-content
  .ant-btn.PushBtn {
  background-color: #ac0000;
  border-color: #ac0000;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
}
.coachesNotesPlays {
  border: 1px solid #eaedf0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}
.coachesNotesPlays .playNum {
  width: max-content;
  background: #0f1a24;
  border-radius: 4px;
  padding: 0 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.coachesNotesPlays .playNum span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;

  /* White */

  color: #ffffff;
  margin-left: 10px;
}

.coachesNotesPlays .playFeedBack h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 161.8%;
  color: #0f1a24;
}
.coachesNotesPlays .playFeedBack p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 161.8%;
  border: 1px solid #eaedf0;
  border-radius: 8px;
  color: #0f1a24;
  padding: 8px;
  margin-bottom: 16px;
}

.cols-box-red .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #a40e17;
  border-color: #a40e17;
}

._container {
  padding: 0 50px;
}

.text-center {
  padding: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.folterRow {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.folterRow p {
  padding: 0;
  margin: 6px 20px 0 0;
}

.positiondiv .ant-select {
  border-radius: 4px !important;
  height: 43px !important;
  background-color: white !important;
  border: 1px solid #eaedf0 !important;
  width: 200px !important;
}

.positiondiv .ant-select-selector {
  border: none !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.positiondiv
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  display: flex !important;
  align-items: center !important;
  box-shadow: none !important;
}

.positiondiv .ant-select-selector {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  color: #0f1a24 !important;
}

.positiondiv .ant-select-item-option-content {
  font-family: "Inter", sans-serif !important;
  color: #2e4d6b !important;
  font-size: 14px !important;
}

.positiondiv .ant-btn {
  text-decoration: underline !important;
}

.custom-checkbox .ant-checkbox-inner {
  background-color: #a40e17 !important;
  border-color: #a40e17 !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::before {
  display: none !important;
}

.coachnotestablediv .ant-table {
  border: 1px solid #f3f5f7;
}

.redBackgroundchecks .ant-checkbox-checked .ant-checkbox-inner,
.bluebackgroundchecks .ant-checkbox-checked .ant-checkbox-inner,
.yellowBackgroundPChecks .ant-checkbox-checked .ant-checkbox-inner,
.greyBackgroundPChecks .ant-checkbox-checked .ant-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
  border-radius: 10px;
}

.redBackgroundchecks,
.bluebackgroundchecks,
.yellowBackgroundPChecks,
.greyBackgroundPChecks {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.bluebackgroundchecks .ant-checkbox-checked .ant-checkbox-inner::after {
  font-family: "Font Awesome 5 Free";
  content: url(../../../assets/img/Vectorcheck.svg);
  position: absolute;
  top: 53%;
  left: 53%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

.redBackgroundchecks .ant-checkbox-checked .ant-checkbox-inner::after {
  content: url(../../../assets/img/Vectorxmark.svg);
  position: absolute;
  top: 53%;
  left: 53%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

.yellowBackgroundPChecks .ant-checkbox-checked .ant-checkbox-inner::after {
  content: url(../../../assets/img/Vectorpflag.svg);
  position: absolute;
  top: 53%;
  left: 53%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

.greyBackgroundPChecks .ant-checkbox-checked .ant-checkbox-inner::after {
  content: url(../../../assets/img/Vectorpflag.svg);
  position: absolute;
  top: 53%;
  left: 53%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

.bluebackgroundchecks .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1790ff !important;
}

.redBackgroundchecks .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #a40e17 !important;
}

.yellowBackgroundPChecks .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e2b517 !important;
}
.greyBackgroundPChecks .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d1d1d6 !important;
}

.bluebackgroundchecks .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #1790ff !important ;
}

.redBackgroundchecks .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #a40e17 !important ;
}

.yellowBackgroundPChecks .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #e2b517 !important ;
}

.greyBackgroundPChecks .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #d1d1d6 !important ;
}

/* .coachnotestablediv > .ant-table > .ant-table-thead{
    font-size: 6px !important;
    font-family: "Inter", sans-serif !important;
    color: #526b84;
    font-weight: 700;
    padding: 4px !important;
    background-color: red !important;
    display: none !important;
  }
  .coachnotestablediv > .ant-table > .ant-table-tbody > tr > td {
    font-size: 6px !important;
    font-family: "Inter", sans-serif !important;
    background-color: yellow !important;
    color: #0f1a24 !important;
    padding: 0px !important;
  } */

@media print {
  .playNum {
    font-size:14px;
  }
  .coachnotestablediv > .ant-table-wrapper .ant-table-tbody > tr > td {
    font-size: 14px !important ;

  }
  .coachnotestablediv > .ant-table-wrapper .ant-table-thead > tr > th {
    font-size: 14px !important ;
    border-right: 2px solid #f3f5f7;
  }
  .coachesNotesPlays .playFeedBack p, .coachesNotesPlays .playFeedBack h5{
   font-size: 14px !important; 
  }
  .coachnotestablediv .ant-table-thead > tr > th, .coachnotestablediv .ant-table-tbody > tr > td {
    height: 50px !important;
    padding: 0 1px !important;
  }
}

