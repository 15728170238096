.CreateMvpsForm {
    width: 100%;
    float: left;
    padding: 30px 0;
}
.CreateMvpsForm .PlayerprimaryCard {
    padding: 15px 15px;
    margin: 0;
}
.TableContainer {
    width: 100%;
    float: left;
}
.TableTeamImg {
    width: 25px;
}
.TableFilter {
    width: 100%;
    float: left;
    padding: 15px 0;
}
.TableFilterText {
    font-size: 14px;
    margin-right: 10px;
    font-weight: 700;
}
.ant-btn.TableActionBtn{
        background-color: #fff;
    color: #9c2729;
    border-color: #9c2729;
}
.ant-btn.TableActionBtn:hover, 
.ant-btn.TableActionBtn:active, 
.ant-btn.TableActionBtn:focus {
background-color: #9c2729;
    color: #fff;
    border-color: #9c2729;
}
 