.Footer {
	width: 100%;
	float: left;
	padding: 20px 0;
	background-color: #fff;
	position: absolute;
	bottom: 0;
}
.footerLeft {
	display: flex;
	align-items: 'center';
	float: left;
}
.footerLeft a,.footerLeft a:hover,.footerLeft a:active,.footerLeft a:focus {
	font-size: 13px;
	color: #000;
	font-weight: 400;
	margin-left: 5px;
	text-decoration: none;
	font-family: 'Roboto', sans-serif;
}
.footerLeft a:first-child{
	margin-left: 0;
}
.copyRights {
	float: right;
	font-size: 13px;
	color: #000;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
}