.notificationmodal .ant-modal-close-x {
  display: none !important;
}
.table-actions .eye-img {
  width: 100px !important;
}

.pushnotificationmodal > .anticon {
  color: #A9B6C2;
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
}

.pushnotificationmodal .ant-switch {
  background-color: #A40E17 !important;
}
.pushnotificationmodal .ant-switch-checked {
  background-color: #1790FF !important;
}
