.CreateCoachForm {
  width: 100%;
  float: left;
  padding: 30px 0;
}
.CreateCoachForm .PlayerprimaryCard {
      padding: 15px 15px;
      margin: 0;
}
.coaches-list-action-btns .TableActionBtn{
  min-width: 75px;
}
.coaches-list-action-btns .TableActionBtn.edit{
  border-color: #1890FF;
  color: #1890FF;
}
.coaches-list-action-btns .TableActionBtn.edit:hover,
.coaches-list-action-btns .TableActionBtn.edit:active,
.coaches-list-action-btns .TableActionBtn.edit:focus{
  border-color: #1890FF;
  color: #fff;
  background-color: #1890FF;
}
/* 21-02-2020 */
.evaluvationPage .ant-collapse {
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
}
.evaluvationPage .ant-collapse > .ant-collapse-item {
      width: 100%;
    float: left;
    border-bottom: 1px solid #f0f0f0;
    background-color: #b23a41;
    margin-bottom: 15px;
        position: relative;
}
.evaluvationPage .ant-collapse > .ant-collapse-item:last-child {
  margin-bottom: 0;
}
.evaluvationPage .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: rgb(0 0 0);
    background-color: #d3d3d3;
}
.evaluvationPage .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
      color: rgb(255 255 255);
    background-color: #9c2d36;
}
.evaluvationPage .ant-collapse-content {
      width: 100%;
    float: left;
}
.evaluvationPage .ant-collapse-content > .ant-collapse-content-box {
    padding: 15px;
    width: 100%;
    float: left;
}
.evaluvationPage .pageTitle {
  width: 100%;
    float: left;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    padding: 15px 0;
    padding-top: 10px;
}
.evaluvationPage .CreateGameBtn {
      position: absolute;
    top: 7px;
    right: 16px;
}
.weeklyAwards .ant-table.ant-table-middle{
  margin-left: 0 !important;
}
.weeklyAwards .pageTitle{
  padding-top: 0 !important;
  margin-bottom: 5px;
}

.weeklyAwards .TableContainer{
  margin-bottom: 20px;
}

