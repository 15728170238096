@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.playerListTable .ant-table-thead > tr > th {
  font-size: 12px !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 700 !important;
}

.playerListTable .ant-table-tbody > tr > td {
  font-size: 12px !important;
  font-family: "Inter", sans-serif !important;
  background-color: white !important;
  color: #0f1a24 !important;
  font-weight: 400 !important;
}

/* screens below 1301px */
@media screen and (max-width: 1300px) {
  .playerListTable .ant-table-thead > tr > th,
  .playerListTable .ant-table-tbody > tr > td {
    font-size: 11px !important;
  }
}

.title {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  height: 7vh;
  align-items: center;
  font-family: "Poppins";
  margin-bottom: 1.37vh;
  margin-top: 2.785vh;
}

.title > h1 {
  color: #0f1a24;
  font-size: 2.3vw;
}

.addplayerdiv {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  background: #1a91ff;
  width: 166px;
  border-radius: 4px;
  height: 100%;
  cursor: pointer;
}

.addplayerdiv > img {
  width: 10.8%;
}

.addplayerdiv > span {
  font-size: 14px;
  font-family: "Inter";
  margin-left: 5%;
  font-weight: 700;
}

.SeassonsCardContainer {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 10px 0;
}

.SeassonsNameYear {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgb(0 0 0 / 45%);
}

.SeassonsNameYear i {
  font-size: 10px;
  margin: 0 5px;
}

.SeassonsYear {
  color: rgb(0 0 0 / 85%);
  font-weight: 700;
}

.SeassonsPlace {
  width: 100%;
  float: left;
  font-size: 12px;
  color: #000000;
  line-height: 147.5%;
}

.SeassonsCardButtonContainer {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.SeassonsCardButtonContainer .ant-btn {
  font-size: 13px;
  line-height: 22px;
  color: rgb(0 0 0 / 85%);
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  height: auto !important;
  padding: 2px 8px;
}

.SeassonsCardButtonContainer .ant-btn:hover,
.SeassonsCardButtonContainer .ant-btn:active,
.SeassonsCardButtonContainer .ant-btn:focus {
  color: #ac0000;
  border-color: #ac0000;
}

.PlayerFilter {
  width: 100%;
  float: left;
  margin: 10px 0;
  padding: 10px 0;
}

.PlayerFilterTitle {
  width: 100%;
  float: left;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}

.PlayerFilterForm {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}

.PlayerFilterForm .ant-col.ant-form-item-label {
  height: auto;
  line-height: 0;
}

.PlayerFilterForm .ant-col.ant-form-item-label > label {
  height: auto;
  line-height: 0;
  font-size: 12px;
  line-height: 10px;
}

.PlayerFilterForm .ant-col.ant-form-item-control .ant-input {
  border: 1px solid #d9d9d9;
  height: auto !important;
  padding: 4px 8px;
  border-radius: 2px;
}

.PlayerFilterForm .ant-form-item-control-input-content .ant-btn {
  width: 100%;
  float: left;
  font-size: 12px;
  height: auto;
  padding: 6px 10px;
  margin-top: 6px;
  color: rgb(0 0 0 / 85%);
}
.PlayerFilterForm.ant-form-vertical .ant-form-item {
  margin-bottom: 0;
}

.PlayerFilterForm .ant-form-item-control-input-content .ant-btn:hover,
.PlayerFilterForm .ant-form-item-control-input-content .ant-btn:active,
.PlayerFilterForm .ant-form-item-control-input-content .ant-btn:focus {
  color: #fff;
  border-color: #ac0000;
  background-color: #ac0000;
}

.PlayerFilterForm
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  border: none !important;
}
.PlayerprimaryCard {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px;
  margin: 10px 0;
  border: solid 1px #fff;
  transition: all ease 0.3s;
  cursor: pointer;
}
.primaryCard {
  width: 100%;
  float: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  margin: 10px 0;
  border: solid 1px #fff;
  transition: all ease 0.3s;
  cursor: pointer;
}
.primaryCard.active,
.primaryCard:hover {
  border-color: #fff;
  transition: all ease 0.3s;
}
.userInfoHeader {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}
.Soph {
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  background-color: #c12730;
  color: #fff;
  font-size: 9px;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  line-height: 12px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.SophNo {
  font-size: 22px;
  line-height: 22px;
}
.CardUserImg {
  width: 67px;
  height: 67px;
  border-radius: 100%;
  margin: 0 10px;
}
.UserNameLine {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
}
.CardName {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.CardNameBold {
  font-size: 20px;
  font-weight: 900;
  line-height: 26px;
}
.CardNameLine {
  width: 100%;
  float: left;
  font-size: 11px;
  line-height: 11px;
  color: #607d8b;
  margin-top: 5px;
}
.ExecutedSwitchContainer {
  width: 100%;
  float: left;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  margin-top: 22px;
}
.ExecutedSwitch {
  width: 100%;
  float: left;
  padding: 18px 13px;
  font-size: 12px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
}
.ExecutedSwitch:last-child {
  border-bottom: none;
}
.PrimaryCardForm {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}
.PrimaryCardForm.ant-form .ant-form-item-label > label {
  font-size: 12px;
  color: #000;
  height: 20px;
}
.PrimaryCardForm.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
  min-width: 67px;
  text-align: left;
}
.PrimaryCardForm.ant-form-horizontal .ant-select {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
}
.PrimaryCardForm.ant-form-horizontal .ant-form-item-label > label::after {
  display: none;
}
.PrimaryCardForm.ant-form-horizontal .ant-form-item {
  margin-bottom: 10px;
}
.PrimaryCardForm .ant-btn-primary {
  border-color: #1890ff;
  background: #1890ff;
}
.PrimaryCardFormEmojiCheck.ant-form label {
  font-size: 24px;
}
.PrimaryCardFormEmojiCheck .SmileEmoji {
  color: #2de4ad;
  font-size: 24px;
  width: 22px;
  height: 22px;
}
.PrimaryCardFormEmojiCheck .CryEmoji {
  color: #c12730;
  font-size: 24px;
  width: 22px;
  height: 22px;
}
.PrimaryCardFormEmojiCheck .ant-checkbox + span {
  display: flex;
  align-items: center;
}
.PrimaryCardFormEmojiCheck .ant-checkbox-inner {
  display: none;
}
.PrimaryCardFormEmojiCheck .ant-checkbox + span .SmileEmoji {
  display: none;
}
.PrimaryCardFormEmojiCheck
  .ant-checkbox.ant-checkbox-checked
  + span
  .SmileEmoji {
  display: block;
}
.PrimaryCardFormEmojiCheck .ant-checkbox.ant-checkbox-checked + span .CryEmoji {
  display: none;
}
.PrimaryCardFormEmojiCheck .ant-checkbox {
  opacity: 0;
}
.PaginationContainer {
  width: 100%;
  float: left;
  padding: 15px 0;
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
}
.PaginationLabel {
  font-size: 14px;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.85);
}
.PaginationContainer .ant-pagination-item {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
  border-color: transparent;
  width: 28px;
  height: 28px;
  margin: 0;
  margin-right: 5px;
  min-width: inherit;
}
.PaginationContainer .ant-pagination-item.ant-pagination-item-active {
  background-color: #fff;
  border-color: #1890ff;
  color: #1890ff;
}
.PaginationContainer .ant-pagination-prev .ant-pagination-item-link,
.PaginationContainer .ant-pagination-next .ant-pagination-item-link {
  border-color: transparent;
  background-color: transparent;
  line-height: 10px;
  width: 28px;
  height: 28px;
  margin: 0;
}
button.ant-btn-primary {
    background-color: #1890ff !important;   
  border-color: #1890ff  !important;  
}
.PaginationContainer .ant-pagination-disabled,
.PaginationContainer .ant-pagination-disabled:hover,
.PaginationContainer .ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
  min-width: inherit;
}
.PaginationContainerInner {
  display: flex;
  align-items: center;
}
.cardCheckUncheckContainer {
  margin-top: -52px;
}
.cardCheckUncheckContainer .cardCheck {
  color: #1c8efa;
}
.cardCheckUncheckContainer .cardUncheck {
  color: #d9d9d9;
}
.primaryCard .cardCheckUncheckContainer .cardCheck {
  display: none;
}
.primaryCard.active .cardCheckUncheckContainer .cardCheck {
  display: block;
}
.primaryCard.active .cardCheckUncheckContainer .cardUncheck {
  display: none;
}
.text-success label {
  color: #28a745 !important;
}
.text-danger label {
  color: #dc3545 !important;
}
.CardContainerMainTitle {
  width: 100%;
  float: left;
  font-size: 13px;
  color: #000000;
  padding: 5px 0;
  font-family: "Roboto", sans-serif;
}
.ExecutedSwitchContainer .ant-switch {
  background-color: #df2b2b;
}
.ExecutedSwitchContainer .ant-switch-checked {
  background-color: #1890ff;
}
.PlayerprimaryCard .CardUserImg {
  margin-left: 0;
}
.PlayerprimaryCard .Soph {
  background-color: #fff;
  color: #858585;
  font-size: 10px;
  font-weight: 900;
  border: solid 1px #858585;
}
.PlayerCardModal .ant-modal-header,
.PlayerCardModal .ant-modal-footer,
.PlayerCardModal .ant-modal-close-x {
  display: none;
}
.PlayerCardModal .ant-modal-body {
  padding: 0;
}
.SubmitCancelBtnContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.SubmitCancelBtnContainer .ant-btn {
  min-width: 45%;
}

.depth-chart-main {
  padding: 12px;
  background: #f3f5f7;
  border-radius: 4px;
}
.depth-chart-main .add-player {
  margin: 10px 0;
  width: 100%;
}
.depth-chart-main .ant-card-body {
  padding: 8px;
}
.depth-chart-main {
}
.depth-chart-main .header {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
}
.depth-chart-main .header h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 161.8%;

  color: #0f1a24;
}
.depth-chart-main .header .anticon {
  font-size: 30px;
}
.depth-chart-main .helper {
}

.depth-chart-main .helper .anticon {
  color: #1a91ff;
}
.depth-chart-main .helper p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 161.8%;
  /* or 19px */

  /* Gray */

  color: #2e4d6b;
}

.depth-chart .depth-chart-item .rank {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 161.8%;
  color: #0f1a24;
  padding: 8px;
  margin: auto 0;
  width: 25px;
}
.depth-chart .depth-chart-item {
  display: flex;
  margin-bottom: 8px;
}
.depth-chart .depth-chart-item .body {
  display: flex;
  border: 1px solid #f3f5f7;
  border-radius: 4px;
  padding: 9px 8px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.depth-chart .depth-chart-item .name {
  width: 100%;
  margin: 0 10px;
}
.depth-chart .depth-chart-item .name p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 161.8%;
  color: #0f1a24;
  margin-bottom: 0;
  text-align: left;
}
.depth-chart .depth-chart-item .control {
  display: flex;
}
.depth-chart .depth-chart-item .control div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  /* Foundation/Gray/Light Gray */

  background: #eaedf0;
  border-radius: 4px;
  margin: 0 2px;
}
.depth-chart .depth-chart-item .control .anticon {
  color: #a9b6c2;
  font-weight: 900;
  font-size: 10px;
  cursor: pointer;
}

.players-depth-chart {
}

.players-depth-chart label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 161.8%;
  color: #0f1a24 !important;
}

.players-depth-chart .player:hover {
  background: #e8f4ff;
}

.players-depth-chart h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 161.8%;
  color: #0f1a24;
}
.players-depth-chart .player {
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 4px 6px;
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid #eaedf0;
  border-radius: 4px;
}

.players-depth-chart .player .avatar {
  padding: 8px;
}
.players-depth-chart .player .name {
  width: 100%;
  align-items: center;
  display: flex;
}
.players-depth-chart span.ant-select-selection-item {
  display: flex;
  align-items: center;
}
.players-depth-chart .player .name p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 161.8%;
  color: #0f1a24;
  margin-bottom: 0;
}
.players-depth-chart .players p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 161.8%;
  color: #8698a9;
}
.players-depth-chart .ant-select .ant-select-selector {
  padding: 13px 16px;
  min-height: 48px;
}

.players-depth-chart .multi .ant-select-selection-overflow {
  height: 72px;
}
.players-depth-chart
  .multi
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item {
  align-self: baseline;
  max-width: 100%;
  height: fit-content;
}
.players-depth-chart .player .control .up {
  position: relative;
  bottom: 3px;
  padding-right: 8px;
}
.players-depth-chart .ant-select {
  width: 100% !important;
}

.players-depth-chart .players {
  padding: 14px 0px;
}
.superlarge-btn {
  height: calc(100% - 11px);
  width: 100%;
  background: #f3f5f7;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 161.8%;
  color: #2e4d6b;
}

.playerlistbtns {
  display: flex;
  width: 50%;
  margin-left: 1.5%;
}

.playerlistbtn {
  background-color: #2e4d6b;
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  font-family: "Inter";
  padding: 15px 25px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-table-column-sorter-up svg,
.ant-table-column-sorter-down svg {
  fill: #2e4d6b !important;
  font-size: 10px !important;
  margin-left: 10px !important;
}

.ant-table-cell .ant-table-column-has-sorters,
.ant-table-column-sorters {
  display: flex;
  align-items: center !important;
}
.mydeptchartbtn {
  background-color: #f3f5f7;
  color: #2e4d6b;
  font-size: 13px;
  font-weight: 700;
  font-family: "Inter";
  padding: 15px 25px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playerlistbtn.active {
  background-color: #2e4d6b;
  color: #ffffff;
  border: none;
}

.searchandtotalplayerdiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.8%;
}

.playerlistsearchdiv {
  background-color: white;
  display: flex; 
  border-radius: 4px; 
  align-items: center;
  justify-content: space-between;
}

.totalplayerdiv {
  margin-top: 1%;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
}

.numberofplayer {
  font-weight: 700;
}

.playerlistsearchbox {
  outline: none;
  width: 90%;
  border: none;
  padding: 5px;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
}

.playerlistsearchicon {
  width: 5%;
  height: 8%;
}

.playerlistdeletediv {
  background: #f6e7e8;
  color: #a40e17;
  font-family: "Inter";
  font-weight: 400;
  display: flex;
  padding: 0.5% 0;
  border-radius: 4px;
  flex-direction: row;
  width: 138px;
  height: 40px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1%;
  cursor: pointer;
}

.playerlistdeleteplayer {
}

.playerlisttrashicon {
  width: 20%;
}

/* 
player list table */
.playerlisttable {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.playercontainer {
  display: flex;
  background-color: yellow;
  flex-direction: row;
}

/* table heading div and each table data div */
.tableheaddiv,
.tabledatadiv {
  width: 100%;
  display: flex;
  height: 7vh;
  align-items: center;
  justify-content: space-around;
}

.tableheaddiv {
  background-color: #f3f5f7;
}

.tabledatadiv {
  background-color: #ffffff;
}

/*  table head and table data text */
.tableheaddiv > h2,
.tableheaddiv > div > h2,
.tabledatadiv > h1,
.tabledatadiv > div > h1,
.tableheaddiv > section > h2 {
  font-size: 0.85vw;
}

.tabledatadiv > h1 {
  text-align: center;
}

/* each headings in the table head */
.tableheaddiv > div,
.tableheaddiv > h2,
.tabledatadiv > div,
.tabledatadiv > h1 {
  width: 13.3%;
  height: 100%;
}

.tabledatadiv > div,
.tabledatadiv > img,
.tableheaddiv > section,
.tabledatadiv > section {
  border-left: 1px solid #f3f5f7;
  border-bottom: 1px solid #f3f5f7;
  border-right: 1px solid #f3f5f7;
}

.tableheaddiv > section,
.tabledatadiv > section {
  width: 6.9%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.position,
.action {
  text-align: center;
}

.tableheaddiv > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.tabledatadiv > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabledatadiv > .emaildiv,
.tableheaddiv > .emaildiv {
  width: 20.6%;
}

.olwbdiv {
  justify-content: center;
}

.eye {
  width: 30%;
}

.pencil {
  width: 20%;
  margin-left: 5%;
}

.olwbdiv > img {
  margin-bottom: 7%;
  margin-left: 5%;
}

/* checkbox */
.checkbox {
  margin-left: 2%;
  margin-bottom: 0.5%;
  background-color: white;
  width: 6.9%;
}

/* the arrowup and arrowdown div */
.tableheaddiv > div > div {
  display: flex;
  flex-direction: column;
  height: 50%;
  margin-left: 5%;
  margin-top: 3%;
}

.paginationdiv {
  display: flex;
}

/* responsiveness for playerlist table */

@media screen and (max-width: 800px) {
  .tableheaddiv {
    height: 52px;
  }
}
.drop-downposition {
  position: relative !important;
  bottom: 0 !important;
}
.positiondropdown {
  position: absolute !important;
  top: 0 !important;
}
.positiondropdowncheckbox {
  height: 24px !important;
  width: 24px !important;
}
.positiondropdown .ant-checkbox-inner,
.positiondropdown .ant-checkbox-input {
  transform: scale(1.3);
}

.myswitchbtn label {
  background-color: #fff;
  color: #4096ff  !important;
  width: 220px;
    
    height: 50px;
}
.myswitchbtn label.ant-radio-button-wrapper-checked {
  background-color: #4096ff;
  color: #fff  !important;
  
}
.myswitchbtn label {
  font-size: 12px;
  font-weight: 500;
}

.player-invite h2 {
  font-size: calc(4 * 0.5rem);
  line-height: 1.2;
  font-weight: 700;
}

.player-invite  .banner {
  display: flex;
  padding: 70px;

  margin: 30px 0;
  background-color: hsla(240,83.3%,66.3%,0.15);
  border-radius: calc(3 * 0.5rem ); 
}
.player-invite  .banner .title {
  display: flex; 
 flex-direction: column; 
 align-items: start;
}
.player-invite  .banner h3 {
  font-size: calc(4 * 0.5rem);
  line-height: 1.2;
  font-weight: 700;

}
.player-invite  .banner p {
  display: block;
  font-size: calc(1.75 * 0.5rem );
  line-height: 1.6;
  font-weight: 400;
  color: hsla(252,13%,46%,1);
}
.player-invite  .banner .actions .copy-link {
  border: 1px solid hsla(252, 13%, 46%, 0.25);
  padding: 5px 30px;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 20px;
}

.player-invite  .banner .actions .print {
   background-color: #625df5; 
   color: #fff;
   padding: 5px 30px;
   margin-right: 10px;
   font-size: 12px;
   border-radius: 20px;
}