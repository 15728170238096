@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.LoginContainer {
    width: 100%;
    float: left;
    padding: 30px 0;
    height: 95vh;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    background-image: url('../../assets/img/loginbg.svg');
    background-color: #000000;
        background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.LoginBG {
    width: 100%;
    float: left;
    min-height: 500px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.afterText{
    color: white;
    text-align: center;
}
.rectangle1{
    /* background: #DA3434; */
    width:80vw;
    height: 100vh;
    position: absolute;
    background-image: url('../../assets/img/gradient.svg');
    background-repeat: no-repeat;
    background-position: top left;
}
.rectangle2{
    width:80vw;
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    background-image: url('../../assets/img/gradient.svg');
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-position: bottom left;
    transform: rotate(180deg); 

}
.createAccountText{
    border: 1px solid red;
    display: flex;
}
.LoginpageContainer {
    width: 100%;
    float: left;
    height: 100%; 
    position: relative;
    overflow: hidden;
    padding: 20px 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.LoginLogoContainer {
    width: 100%;
    float: left;
    text-align: center;
    /* border: 1px solid red; */

}
.tester{
    border: 2px solid blue;
}
label{
    /* Medium */

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
/* line-height: 161.8%; */

/* identical to box height, or 23px */


/* White */
color: #FFFFFF  !important;
}
.LoginPageLoginText {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #291B51;
    margin: 10px 0;
    margin-top: 20px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 42px; 
color: #FFFFFF;
 
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.LoginForm {
    width: 100%;
    float: left;
}
.LoginForm .ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: #291B51;
    font-size: 12px;
    font-weight: 500;
}
.LoginForm .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.LoginForm .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}
.LoginForm .ant-input,
.LoginForm .ant-input-affix-wrapper {
    padding: 8px 11px;
    font-size: 14px;
    border: 1px solid #d9d9d9;
}
.LoginForm .loginFormForgot {
    width: 100%;
    font-size: 12px;
    color: #ffffff;
    font-weight: 500;
    margin-top: 10px;
    text-decoration: underline;
}
.LoginForm.ant-form-vertical .ant-form-item {
    flex-direction: column;
    margin-bottom: 15px;
}
.LoginForm .ant-btn.ant-btn-primary {
    width: 100%;
    background-color: #1A91FF;
    border-color: #1A91FF;
    font-weight: 500;
    text-transform: uppercase;
    height: 40px;
    margin-top: 20px;
    border-radius: 4px;
}
.LoginForm .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
    font-size: 11px;
    line-height: 15px;
    min-height: initial;
}
/* .LoginpageContainer:before {
    content: "";
    position: absolute;
    left: -35px;
    top: -150px;
    height: 500px;
    width: 40px;
    background-color: #FDD19D;
    transform: rotateZ(
45deg);
}
.LoginpageContainer:after {
    content: "";
    position: absolute;
    left: -23px;
    top: -105px;
    height: 500px;
    width: 40px;
    background-color: rgba(253, 209, 157, 0.5);
    transform: rotateZ(
45deg);
} */
.forgotPasswordInstruction {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 13px;
    color: #607D8B;
    margin-bottom: 20px;
}
.scribble{
    display: block;
    float: right;
    position: absolute;
    bottom: 16px;
    right: 120px;
}

@media screen and (max-width: 480px) {
    .rectangle1{
        width: 100vw;
        height: 50vh;
        position: absolute;
        background-image: url('../../assets/img/gradient.svg');
        background-repeat: no-repeat;
        background-position: center;
        top: 0;
    }
    .rectangle2{
        width: 100vw;
        height: 100vh;
        position: absolute;
        right: 0;
        top: 0;
        background-image: url('../../assets/img/gradient.svg');
        background-repeat: no-repeat;
        /* background-size: cover; */
        /* background-position: bottom left;
         */
         background-position: center;

        transform: rotate(180deg); 
    }
    .scribble{
        display: block;
        float: right;
        position: absolute;
        right: 80px;
    }
    .LoginPageLoginText {
        width: 100%;
        float: left;
        font-size: 20px !important;
        color: #291B51;
        /* margin: 10px 0; */
        /* margin-top: 20px; */
    
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px; 
    color: #FFFFFF;
     
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}