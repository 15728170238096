@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.side {
  box-shadow: 0px 2px 70px rgba(0, 0, 0, 0.05);
}

.Link:hover img {
  filter: invert(100%) brightness(200%);
}

.active-link {
	background-color: #1A91FF !important;
	color: #ffffff !important;
}

.active-image {
	filter: invert(100%) brightness(200%) !important;
}


/*li.ant-menu-item{  
	
	list-style: none;
 
} 
.ant-menu-inline .ant-menu-item-selected::after {
	opacity: 0;
}
li.ant-menu-item.ant-menu-item-only-child {
    border-radius: 10px;
    margin: 10px 0;
	    padding: 0 !important; 
		padding-left: 0 !important;
		height: auto;
}
li.ant-menu-item .menu-item {  
    background: #F3F5F7;
    border-radius: 4px;
     padding: 8px 12px;
    padding-bottom: 6px;
	    display: block;

} 
li.ant-menu-item a.menu-item{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 161.8%;
color: #1A1C20;
    width: 98%;
}
li.ant-menu-item a.menu-item img {
	margin-right: 8px;
}
li.ant-menu-item .menu-item.active{
	background-color: #1A91FF;
	color: white;
}
li.ant-menu-item.ant-menu-item-only-child {
	padding-left: 16px !important;
}

li.ant-menu-item .menu-item.under-section {

	

}
.menu-container{
	
	height: 100vh;
	background-color: white;
	position: relative;

}
.logout{
	position: absolute;
	bottom: 100px;
	width: 90%;
	right: 5%;;
	margin: 0 auto;
	

} */
/* @media print {
	.hide-in-print {
		display: none !important;
	}
  } */